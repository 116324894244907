.share-button {
    display: flex;
    align-items: center;
}

.share-button__copy {
    padding: 0.5rem 1rem;
    border: none;
    background-color: var(--color-primary);
    color: var(--color-white);
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px 0 0 5px;
}

.share-button__share {
    padding: 0.5rem;
    border: none;
    background-color: var(--color-primary);
    color: var(--color-white);
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share-button__share i {
    margin-right: 0.5rem;
}