.container-layout-post {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  /* margin: 0 auto; */
  max-width: 128rem;
}

.post {
  background: var(--color-white);
  border-radius: 10px;
  /* Added later */
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.container-post {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.post__body {
  margin-top: 2rem;
  padding: 0 2rem;
  background: var(--color-white);
}

.post__author {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.post__image {
  width: 100%;
}

.post__image img {
  width: 100%;
  height: 100%;
}

.post__heading {
  margin: 1.5rem 0;
}

.post__text {
  font-size: 1rem;
  padding: 0 0rem;
  font-weight: normal;
  /* margin: 2rem 0; */
  line-height: 1.8;
}

.post__text>* {
  /* padding: 1rem 0; */
}

.post__text a {
  color: var(--color-primary);
}

.preview__tag--post {
  font-size: 1.6rem;
}

.post__auth {
  display: flex;
  gap: 1rem;
}

.post__auth>* {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

.auth__edit {
  border-radius: var(--border-radius);
  background: var(--color-primary);
  color: var(--color-white);
}

.auth__delete {
  background: var(--btn-bg-red);
  color: var(--color-white);
}

@media (min-width: 768px) {
  .container-layout-post {
    flex-direction: row;
    margin: 0 auto;
  }

  .container-post {
    margin-bottom: 2rem;
    border-radius: 10px;
    width: 88%;
  }

  .post__heading {
    font-size: 2rem;
  }

  .post__image img {
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (min-width: 1024px) {
  .post {
    flex-grow: 3;
    width: 95%;
  }

  .container-post {
    width: 92%;
    flex-direction: row;
  }
}


/* Share Button CSS Start */
.post__share {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.post__share button {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: var(--border-radius);
  padding: 0.8rem 1.2rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.post__share button:hover {
  background-color: var(--color-primary-dark);
}


/* Share Button CSS End */



/* Path: src\components\Post\PostContent\PostContent.js Table CSS*/

/* Add a container element with overflow-x: auto around the table element */
.table-container {
  overflow-x: auto;
  background-color: white;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1.5rem;
}

thead {
  background-color: #f2f2f2;
}

thead th {
  font-weight: bold;
  padding: 0.75rem;
  text-align: left;
  border: 1px solid #ddd;
}

tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

tbody td {
  padding: 0.75rem;
  text-align: left;
  border: 1px solid #ddd;
}









/* ------------------------------------- */
/* Style for the container */
.table-container {
  width: 100%;
  margin: 0 auto;
}

/* Style for headings */
.table-container h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.table-container h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.table-container h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.table-container strong {
  /* font-size: 20px; */
  font-weight: bold;
  /* margin-bottom: 10px; */
}

.table-container i {
  font-style: italic;
  color: #464343;
}

.table-container p {
  margin-bottom: 0.5rem;
}

/* Style for links */
.table-container a {
  color: #0077cc;
  text-decoration: none;
}

.table-container a:hover {
  text-decoration: underline;
}

/* Style for lists */
.table-container ul,
.table-container ol {
  margin-bottom: 1rem;
  padding-left: 0.5rem;
}

.table-container li {
  margin-bottom: 5px;
  margin-left: 1.3rem;
}

/* Style for code blocks */
.table-container pre {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Consolas, monospace;
  font-size: 1rem;
  overflow: auto;
  padding: 10px;
  margin-bottom: 20px;
}

.table-container code {
  font-family: Consolas, monospace;
  font-size: 14px;
}

/* Style for inline code */
.table-container p code {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: Consolas, monospace;
  font-size: 14px;
  padding: 2px 4px;
}

/* Style for lists */
.table-container ul,
.table-container ol {
  margin-bottom: 1rem;
  padding-left: 0.5rem;
}

.table-container ul li,
.table-container ol li {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #333;
}

.table-container ul li::before {
  content: "•";
  color: #0077cc;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.table-container ol li::before {
  /* content: counter(li-counter); */
  /* counter-increment: li-counter; */
  color: #0077cc;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Style for bold list numbering/bullets/alphabets */
.table-container ul,
.table-container ol {
  counter-reset: list-counter;
}

.table-container ul li::before,
.table-container ol li::before {
  font-weight: bold;
}


@media (max-width: 768px) {
  body {
    font-size: 0.8rem;
  }

  table {
    font-size: 0.7rem;
  }

  thead {
    display: none;
  }

  tbody td:before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  tbody td:last-of-type {
    border-bottom: 0;
  }

  .post {
    padding: 0.5rem;
  }

  .post__body {
    padding: 0 0.5rem;
  }

  .table-container pre {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  thead th {
    padding: 0.5rem;
    text-align: center;
    border: 1px solid #ddd;
  }

  tbody td {
    padding: 0.4rem;
    text-align: left;
    border: 1px solid #ddd;
  }
}