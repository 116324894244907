.coming-soon-container {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.coming-soon-card {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 0 .625rem rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  text-align: center;
  max-width: 40rem;
  margin: 0 1rem;
}

.coming-soon-header {
  font-size: 2.25rem;
  margin-bottom: 1.25rem;
}

.coming-soon-text {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}

@media only screen and (max-width: 768px) {
  .coming-soon-card {
    padding: 1.25rem;
    margin: 0;
  }

  .coming-soon-header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .coming-soon-text {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 480px) {
  .coming-soon-card {
    padding: 1rem;
  }

  .coming-soon-header {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .coming-soon-text {
    font-size: 0.75rem;
  }
}