.skeleton {
    background: #ddd;
    margin: 1rem 0;
    border-radius: 4px;
  }
  
  .skeleton.text {
    width: 100%;
    height: 1.2rem;
  }
  
  .skeleton.span {
    width: 50%;
    height: 1rem;
  }
  
  .skeleton.title {
    width: 50%;
    height: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .skeleton.avatar {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
  }
  
  .skeleton.thumbnail {
    width: 100%;
    height: 20rem;
  }
  
  @media (min-width: 500px) {
    .skeleton.thumbnail {
      width: 100%;
      height: 30rem;
    }
  }
  
  .skeleton-wrapper {
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
    background: #f2f2f2;
    /* min-height: 100vh; */
  }
  
  .skeleton-article {
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: var(--border-radius);
  }
  
  .skeleton--page {
    margin: 0 auto;
    max-width: 116rem;
    margin-top: 5rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  
  .skeleton--post {
    width: 100%;
  }
  
  .skeleton--author {
    display: none;
    width: 25%;
  }
  
  .skeleton--post .skeleton.thumbnail {
    height: 43rem;
  }
  
  .skeleton-tags {
    display: flex;
  }
  
  @media (min-width: 1024px) {
    .skeleton--author {
      display: block;
    }
  
    .skeleton--post {
      width: 70%;
    }
  }
  
  .shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 0.5s infinite;
  }
  
  .shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skew(-20deg);
    box-shadow: 0 0 3rem 3rem rgba(255, 255, 255, 0.5);
  }
  
  @keyframes loading {
    0% {
      transform: translateX(-150%);
    }
    50% {
      transform: translateX(-60%);
    }
    100% {
      transform: translateX(150%);
    }
  }
  
  .skeleton__tags {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .skeleton__tag-item {
    border: 1px solid #fafafa;
    border-top: 1.6rem solid #000;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 1rem 2rem;
    min-height: 20rem;
  }
  
  @media (min-width: 768px) {
    .skeleton__tags {
      flex-direction: row;
    }
    .skeleton__tag-item {
      width: 48.9%;
    }
  }
  
  @media (min-width: 1024px) {
    .skeleton__tag-item {
      width: 32%;
    }
  }
  