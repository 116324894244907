.news-card {
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px var(--main-theme);
  
    &__image {
      width: 100%;
      height: auto;
      border-radius: 5px 5px 0 0;
    }
  
    &__details {
      padding: 1rem;
  
      &__header {
        display: flex;
        align-items: center;
  
        h3 {
          font-size: 1.25rem;
          flex: 1;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
  
        small {
          font-size: 0.875rem;
          color: var(--main-theme);
          font-weight: lighter;
        }
      }
  
      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
  
        li {
          padding: 1rem;
          border-top: 1px solid var(--main-theme-lighter);
          border-bottom: 1px solid var(--main-theme-lighter);
          cursor: pointer;
          transition: all 0.2s;
  
          &:hover {
            background-color: var(--main-theme-lightest);
            color: var(--main-theme-darker);
          }
  
          small {
            color: var(--main-6);
            font-size: 0.875rem;
          }
  
          span {
            background-color: var(--main-theme);
            color: #fff;
            font-size: 0.75rem;
            padding: 0.25rem;
            border-radius: 5px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  