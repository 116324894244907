.news-card {
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
}

.news-card__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    filter: brightness(0.5) blur(5px);
    transition: filter 0.5s ease-in-out;
}

.news-card:hover .news-card__background {
    filter: brightness(0.8) blur(2px);
}

.news-card__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    color: white;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
}

.news-card:hover .news-card__content {
    transform: translateY(0%);
}

.news-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;
}

.news-card__source {
    text-transform: uppercase;
}

.news-card__title {
    font-size: 18px;
    margin-bottom: 10px;
}

.news-card__description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    font-size: 14px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
}

.news-card__description--visible {
    opacity: 1;
    visibility: visible;
}

.news-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.news-card:hover .news-card__description {
    opacity: 1;
    visibility: visible;
}

.news-card__description-text {
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {
    .news-card {
        height: auto;
        min-height: 350px;
    }

    .news-card__background {
        height: 100%;
        filter: brightness(0.5) blur(5px);
    }

    .news-card:hover .news-card__background {
        filter: brightness(0.8) blur(2px);
    }

    .news-card__content {
        position: static;
        transform: none;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 10px;
    }

    .news-card__title {
        font-size: 16px;
    }

    .news-card__description {
        position: static;
        opacity: 1;
        visibility: visible;
        margin-top: 10px;
    }

    .news-card__source {
        display: none;
    }
}