/* Basic styling for the query-param-table container */
.query-param-table {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    transition: all 0.3s;
}

/* Styling for the URL input container */
.url-input-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Styling for the URL input field */
input[type="text"] {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
}

/* Styling for the "Parse URL" button */
button {
    background-color: #008CBA;
    color: #fff;
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #00587D;
}

.table-container {
    margin-top: 10px;
}

/* Styling for the table */
table {
    font-size: 14px;
    /* Adjust the font size as needed */
    width: 100%;
}

/* Styling for table headers and cells */
th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    transition: background-color 0.3s;
    max-width: 40ch;
    /* Set the maximum width for the "Value" cells to 30 characters */
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Styling for the first column */
th:first-child,
td:first-child {
    max-width: 16ch;
    /* Adjust the maximum width for the first column */
}

/* Styling for the third column */
th:nth-child(3),
td:nth-child(3) {
    max-width: 7ch;
    /* Adjust the maximum width for the third column */
}


/* Styling for table headers */
th {
    background-color: #f2f2f2;
}

/* Styling for table rows on hover */
tr:hover {
    background-color: #f9f9f9;
}

/* Styling for the "Copied!" button */
button.copied {
    background-color: #4CAF50;
    cursor: not-allowed;
}

/* Styling for key-value pairs */
.key-value {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.key-value span {
    font-weight: bold;
    margin-right: 5px;
}

.key-value p {
    margin: 0;
}